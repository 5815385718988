@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVAewA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyCMIT5lu.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@media (max-width: 800px) {
  .workStyle-definition-container {
    margin: 0 auto;
  }
}

.workStyle-definition-container > a {
  padding-top: 50px;
}

.workStyle-definition-container a {
  display: block;
  clear: both;
  height: 25px;
}

.workStyle-definition-container img {
  width: 57px;
  height: 57px;
  float: left;
  margin-right: 10px;
}

.workStyle-definition-container div {
  color: gray;
}

.workStyle-definition-container div > h4 {
  font-weight: bold;
  text-transform: uppercase;
}

.workStyle-definition-container div > p {
  font-size: small;
}

div.selected {
  color: white;
}

.modal .modal-content {
  background-color: #000;
  color: #fff;
}

#high-level-description {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(192, 192, 192, var(--tw-text-opacity));
}

#in-your-ideal-work-environment {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(192, 192, 192, var(--tw-text-opacity));
}

#in-a-strained-work-environment {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(192, 192, 192, var(--tw-text-opacity));
}

.modal-content h4 {
  font-family: "Open Sans";
  font-weight: 700;
}

.advice {
  color: #000;
}

#modalBody-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  margin: 0;
}

.artisan {
  background-image: url("../images/workstyle/artisan-bg.svg");
}

.artisan .advice {
  background-color: rgb(47, 178, 188);
}

.producer {
  background-image: url("../images/workstyle/producer-bg.svg");
}

.producer .advice {
  background-color: rgb(193, 21, 167);
}

.partner {
  background-image: url("../images/workstyle/partner-bg.svg");
}

.partner .advice {
  background-color: rgb(99, 197, 140);
}

.leader {
  background-image: url("../images/workstyle/leader-bg.svg");
}

.leader .advice {
  background-color: rgb(38, 132, 232);
}

.innovator {
  background-image: url("../images/workstyle/innovator-bg.svg");
}

.innovator .advice {
  background-color: rgb(221, 150, 75);
}
