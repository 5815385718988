@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWV4ewJER.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyCMIT5lu.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyCMIT5lu.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyCMIT5lu.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/*//////////////////////////////////////////////////////////////////
[ Contact ]*/
.container-contact100 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.wrap-contact100 {
  width: 550px;
  background: transparent;
  padding: 30px 0 80px 0;
}

/*==================================================================
  [ Form ]*/

.contact100-form {
  width: 100%;
}

.contact100-form-title {
  display: block;
  font-size: 30px;
  color: #43383e;
  line-height: 1.2;
  text-align: left;
  padding-bottom: 35px;
}

/*------------------------------------------------------------------
  [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  background-color: #fff;
  margin-bottom: 17px;
}

.label-input100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 62px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  font-size: 18px;
  color: #999999;
}

.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-size: 15px;
  color: #43383e;
  line-height: 1.2;
  padding: 0 5px;
}

/*---------------------------------------------*/
input.input100 {
  height: 62px;
  padding: 0 20px 0 58px;
}

textarea.input100 {
  min-height: 199px;
  padding: 19px 20px 0 23px;
}

/*==================================================================
  [ Restyle Checkbox ]*/

.contact100-form-checkbox {
  padding-top: 12px;
  padding-bottom: 20px;
}

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
}

.label-checkbox100::before {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  background: #fff;
  left: 0;
  top: 50%;
  vertical-align: top;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked + .label-checkbox100::before {
  color: green;
}

.button {
  border: 1px solid green;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transform: skewX(-10deg);
  height: 50px;
  width: 200px;
  border-radius: 20px 5px 20px 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font: 15px sans-serif;
  font-weight: 300;
  text-shadow: 0 0 20px #fff;
  text-transform: uppercase;
  -webkit-animation: breath2 2s 0.5s infinite alternate;
  animation: breath2 2s 0.5s infinite alternate;
  transition: all 0.2s ease;
  cursor: pointer;
}
.button:before {
  content: "";
  display: block;
  width: calc(100% - 22px);
  height: calc(50px - 8px);
  -webkit-animation: breath 2s infinite alternate;
  animation: breath 2s infinite alternate;
  left: 10px;
  top: 3px;
  position: absolute;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 15px 3px 15px 3px;
}
.button.fire {
  border-color: #ffeca8;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 138, 48, 0.6),
    rgba(240, 96, 29, 0.6)
  );

  box-shadow: 0 0 70px rgba(255, 138, 48, 0.6),
    0 5px 20px rgba(255, 138, 48, 0.6), inset 0 1px #ffeca8,
    inset 0 -1px #ffeca8;
  color: #ffeca8;
}
.button.fire:before {
  box-shadow: inset 0 0 30px 0 #ffeca8;
}
.button.ice {
  border-color: #a8ecff;
  background-image: linear-gradient(
    to bottom,
    rgba(48, 138, 255, 0.5),
    rgba(29, 96, 240, 0.5)
  );
  box-shadow: 0 0 70px rgba(48, 138, 255, 0.5),
    0 5px 20px rgba(48, 138, 255, 0.5), inset 0 1px #ffeca8,
    inset 0 -1px #ffeca8;
  color: #a8ecff;
}

.button.ice:before {
  box-shadow: inset 0 0 30px 0 #a8ecff;
}

.button:hover.fire {
  box-shadow: 0 0 70px rgba(255, 138, 48, 0.8),
    0 5px 20px rgba(255, 138, 48, 0.8), inset 0 1px #ffeca8,
    inset 0 -1px #ffeca8;
}

.button:hover.fire:before {
  box-shadow: inset 0 0 50px 0 #ffeca8;
}

.button:hover.ice {
  box-shadow: 0 0 70px rgba(48, 138, 255, 0.8),
    0 5px 20px rgba(48, 138, 255, 0.8), inset 0 1px #a8ecff,
    inset 0 -1px #a8ecff;
  color: #fff;
}
.button:hover.ice:before {
  box-shadow: inset 0 0 50px 0 #a8ecff;
}
.button + .button {
  margin-top: 15px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes breath {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0.95);
  }
}

@keyframes breath {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0.95);
  }
}
@-webkit-keyframes breath2 {
  0% {
    transform: skewX(-10deg) scaleX(1);
  }
  100% {
    transform: skewX(-10deg) scaleX(0.95);
  }
}
@keyframes breath2 {
  0% {
    transform: skewX(-10deg) scaleX(1);
  }
  100% {
    transform: skewX(-10deg) scaleX(0.95);
  }
}
.ref {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  padding: 5px 8px;
  position: absolute;
  font-size: 16px;
  bottom: 10px;
  right: 10px;
  color: #fff;
  font-weight: 300;
  font-family: sans-serif;
  text-decoration: none;
}
.ref::first-letter {
  font-size: 12px;
}

/*------------------------------------------------------------------
  [ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2px;
  pointer-events: none;

  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}
